import httpService from './HttpService';
import { HTTP_METHODS } from 'consts';

const ROUTES = {
  GET_ALL_PROJECT_TYPES: '/project-types',
  GET_PROJECTS: '/projects',
  GET_PROJECT: '/projects/single/:projectId',
  GET_PROJECT_GRID: '/projects/grid/:page/:limit/',
  GET_PROJECT_GRID_BY_RECRUITER_ID:
    '/projects/by-recruiter/:recruiterId/grid/:page/:limit/',
  DELETE_PROJECT: '/projects/:projectId',
  CLOSE_PROJECT: '/projects/close/:projectId',
  CREATE_PROJECT: '/projects',
  UPDATE_PROJECT: '/projects/:projectId',
  CHANGE_ADMIN: '/projects/change-admin/:projectId',
  DOWNLOAD_ZIP: '/projects/export-zip/:projectId',
  DOWNLOAD_EXCEL: '/projects/export-excel/:projectId',
  DOWNLOAD_EXCEL_ALL: '/projects/export-all',
};

class ProjectService {
  constructor(httpService) {
    this.httpService = httpService;
  }

  getAllProjectTypes = () => {
    return this.httpService.request({
      url: ROUTES.GET_ALL_PROJECT_TYPES,
      method: HTTP_METHODS.GET,
    });
  };
  getProject = (projectId) => {
    return this.httpService.request({
      url: ROUTES.GET_PROJECT.replace(':projectId', projectId),
      method: HTTP_METHODS.GET,
    });
  };

  downloadZip = (projectId) => {
    return this.httpService.request({
      url: ROUTES.DOWNLOAD_ZIP.replace(':projectId', projectId),
      method: HTTP_METHODS.GET,
      responseType: 'arraybuffer',
    });
  };

  downloadExcel = (projectId) => {
    return this.httpService.request({
      url: ROUTES.DOWNLOAD_EXCEL.replace(':projectId', projectId),
      method: HTTP_METHODS.GET,
    });
  };

  downloadExcelAll = () => {
    return this.httpService.request({
      url: ROUTES.DOWNLOAD_EXCEL_ALL,
      method: HTTP_METHODS.GET,
      responseType: 'arraybuffer',
    });
  };

  getProjects = () => {
    return this.httpService.request({
      url: ROUTES.GET_PROJECTS,
      method: HTTP_METHODS.GET,
    });
  };

  getProjectGrid = (page, limit, order, data) => {
    return this.httpService.request({
      url:
        ROUTES.GET_PROJECT_GRID.replace(':page', page).replace(
          ':limit',
          limit
        ) + (order ? order.type + '/' + order.order : ''),
      method: HTTP_METHODS.POST,
      data,
    });
  };

  createProject = (data) => {
    return this.httpService.request({
      url: ROUTES.CREATE_PROJECT,
      method: HTTP_METHODS.POST,
      data,
    });
  };

  updateProject = (projectId, data) => {
    return this.httpService.request({
      url: ROUTES.UPDATE_PROJECT.replace(':projectId', projectId),
      method: HTTP_METHODS.PUT,
      data,
    });
  };

  changeAdmin = (projectId, data) => {
    return this.httpService.request({
      url: ROUTES.CHANGE_ADMIN.replace(':projectId', projectId),
      method: HTTP_METHODS.PUT,
      data,
    });
  };

  getProjectGridByRecruiterId = (recruiterId, page, limit, order) => {
    return this.httpService.request({
      url:
        ROUTES.GET_PROJECT_GRID_BY_RECRUITER_ID.replace(
          ':recruiterId',
          recruiterId
        )
          .replace(':page', page)
          .replace(':limit', limit) +
        (order ? order.type + '/' + order.order : ''),
      method: HTTP_METHODS.POST,
    });
  };

  deleteProject = (projectId) => {
    return this.httpService.request({
      url: ROUTES.DELETE_PROJECT.replace(':projectId', projectId),
      method: HTTP_METHODS.DELETE,
    });
  };

  closeProject = (projectId) => {
    return this.httpService.request({
      url: ROUTES.CLOSE_PROJECT.replace(':projectId', projectId),
      method: HTTP_METHODS.PUT,
    });
  };
}

const projectService = new ProjectService(httpService);

export default projectService;
