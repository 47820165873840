import { call, takeLatest, put } from 'redux-saga/effects';
import { setProjects, setProject } from './actions';
import projectService from '../../services/ProjectService';
import {
  GET_PROJECTS_SAGA,
  DELETE_PROJECT_SAGA,
  GET_PROJECT_SAGA,
  CREATE_PROJECT_SAGA,
  CLOSE_PROJECT_SAGA,
} from './actionTypes';
import { push } from 'connected-react-router';
import { PROJECT_LIST } from 'routes';
import { setSnackbar } from '../snackbar/actions';
import { $t } from '../../translations';
import moment from 'moment';

export function* getProjectsSaga() {
  try {
    const projects = yield call(projectService.getProjects);
    yield put(setProjects(projects));
  } catch (error) {
    console.log({ error });
    yield put(
      setSnackbar({
        open: true,
        severity: 'error',
        message: error.data.message,
      })
    );
  } finally {
  }
}

export function* getProjectSaga({ projectId }) {
  try {
    const project = yield call(projectService.getProject, projectId);
    yield put(setProject(project));
  } catch (error) {
    console.log({ error });
    yield put(
      setSnackbar({
        open: true,
        severity: 'error',
        message: error.data.message,
      })
    );
  } finally {
  }
}

export function* deleteProjectSaga({ projectId }) {
  try {
    yield call(projectService.deleteProject, projectId);
    yield put(
      setSnackbar({
        open: true,
        severity: 'error',
        message: $t('snackbar.message.project.deleted'),
      })
    );
    yield put(push(PROJECT_LIST));
  } catch (error) {
    console.log({ error });
    yield put(
      setSnackbar({
        open: true,
        severity: 'error',
        message: error.data.message,
      })
    );
  } finally {
  }
}

export function* closeProjectSaga({ projectId }) {
  try {
    yield call(projectService.closeProject, projectId);
    yield put(
      setSnackbar({
        open: true,
        severity: 'success',
        message: $t('snackbar.message.project.closed'),
      })
    );
    yield put(push(PROJECT_LIST));
  } catch (error) {
    console.log({ error });
    yield put(
      setSnackbar({
        open: true,
        severity: 'error',
        message: error.data.message,
      })
    );
  } finally {
  }
}

export function* createProjectSagaFn({ project }) {
  try {
    const data = {};
    data.end_date = moment(project.end_date).format('YYYY-MM-DD');
    data.start_date = moment(project.start_date).format('YYYY-MM-DD');
    data.name = project.name;
    data.project_type_id = project.project_type_id;
    data.project_number = project.project_number;
    data.number_of_journeys = project.number_of_journeys;
    data.journey_id = project.journey_id;
    if (project?.admin_id) {
      data.admin_id = project.admin_id;
    }

    let conditionIdsArr = [];
    project.conditionIds.forEach((item) => {
      conditionIdsArr.push(item.id);
    });
    let diagnosedConditionIds = [];
    project.diagnosedConditionIds.forEach((item) => {
      diagnosedConditionIds.push(item.id);
    });
    let typeOfRespondentIdsArr = [];
    project.typeOfRespondentIds.forEach((item) => {
      typeOfRespondentIdsArr.push(item.id);
    });
    let therapeuticCategoryIdsArr = [];
    project.therapeuticCategoryIds.forEach((item) => {
      therapeuticCategoryIdsArr.push(item.id);
    });
    let userIdsArr = [];
    project.userIds.forEach((item) => {
      userIdsArr.push(item.id);
    });
    let recruiterIdsArr = [];
    project.recruiterIds.forEach((item) => {
      recruiterIdsArr.push(item.id);
    });

    let countryIdsArr = [];
    project.countryIds.forEach((item) => {
      countryIdsArr.push(item.id);
    });

    let careIdsArr = [];
    project.careIds.forEach((item) => {
      careIdsArr.push(item.id);
    });

    // let healthcareProfessionalIdsArr = [];
    // project.healthcareProfessionalIds.forEach((item) => {
    //   healthcareProfessionalIdsArr.push(item.id);
    // });

    data.conditionIds = conditionIdsArr;
    data.diagnosedConditionIds = diagnosedConditionIds;
    data.countryIds = countryIdsArr;
    data.recruiterIds = recruiterIdsArr;
    data.userIds = userIdsArr;
    data.therapeuticCategoryIds = therapeuticCategoryIdsArr;
    data.typeOfRespondentIds = typeOfRespondentIdsArr;
    data.careIds = careIdsArr;
    // data.healthcareProfessionalIds = healthcareProfessionalIdsArr;

    yield call(projectService.createProject, data);

    yield project.ref?.current?.refresh();
  } catch (error) {
    console.log({ error });
    yield put(
      setSnackbar({
        open: true,
        severity: 'error',
        message: error.data.message,
      })
    );
  } finally {
  }
}

export default function* projectSaga() {
  yield takeLatest(GET_PROJECT_SAGA, getProjectSaga);
  yield takeLatest(DELETE_PROJECT_SAGA, deleteProjectSaga);
  yield takeLatest(CLOSE_PROJECT_SAGA, closeProjectSaga);
  yield takeLatest(GET_PROJECTS_SAGA, getProjectsSaga);
  yield takeLatest(CREATE_PROJECT_SAGA, createProjectSagaFn);
}
