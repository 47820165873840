import {
  GET_PROJECTS_SAGA,
  SET_PROJECTS,
  DELETE_PROJECT_SAGA,
  GET_PROJECT_SAGA,
  SET_PROJECT,
  SET_PROJECT_ID,
  CREATE_PROJECT_SAGA,
  CLOSE_PROJECT_SAGA,
} from './actionTypes';

export function getProjectsSaga() {
  return {
    type: GET_PROJECTS_SAGA,
  };
}

export function setProjects(payload) {
  return {
    type: SET_PROJECTS,
    payload,
  };
}

export function getProjectSaga(projectId) {
  return {
    type: GET_PROJECT_SAGA,
    projectId,
  };
}

export function setProject(payload) {
  return {
    type: SET_PROJECT,
    payload,
  };
}

export function deleteProjectSaga(projectId) {
  return {
    type: DELETE_PROJECT_SAGA,
    projectId,
  };
}
export function closeProjectSaga(projectId) {
  return {
    type: CLOSE_PROJECT_SAGA,
    projectId,
  };
}

export function setProjectId(payload) {
  return {
    type: SET_PROJECT_ID,
    payload,
  };
}
export function createProjectSaga(project) {
  return {
    type: CREATE_PROJECT_SAGA,
    project,
  };
}
