import httpService from './HttpService';
import { HTTP_METHODS } from 'consts';

const ROUTES = {
  GET_ADMIN_GRID: '/admins/grid/:page/:limit/',
  EDIT_ADMIN: '/admins/:id',
  GET_ADMIN: '/admins/:id',
  DELETE_ADMIN: '/admins/:id',
  CREATE_ADMIN: '/admins/',
  GET_ALL_ADMINS: '/admins',
};

class AdminService {
  constructor(httpService) {
    this.httpService = httpService;
  }

  getAdminGrid = (page, limit, order, data) => {
    return this.httpService.request({
      url:
        ROUTES.GET_ADMIN_GRID.replace(':page', page).replace(':limit', limit) +
        (order ? order.type + '/' + order.order : ''),
      method: HTTP_METHODS.POST,
      data,
    });
  };

  getAllAdmins = () => {
    return this.httpService.request({
      url: ROUTES.GET_ALL_ADMINS,
      method: HTTP_METHODS.GET,
    });
  };

  editAdmin = (data, id) => {
    return this.httpService.request({
      url: ROUTES.EDIT_ADMIN.replace(':id', id),
      method: HTTP_METHODS.PUT,
      data,
    });
  };

  getAdmin = (id) => {
    return this.httpService.request({
      url: ROUTES.GET_ADMIN.replace(':id', id),
      method: HTTP_METHODS.GET,
    });
  };

  deleteAdmin = (id) => {
    return this.httpService.request({
      url: ROUTES.DELETE_ADMIN.replace(':id', id),
      method: HTTP_METHODS.DELETE,
    });
  };

  createAdmin = (data) => {
    return this.httpService.request({
      url: ROUTES.CREATE_ADMIN,
      method: HTTP_METHODS.POST,
      data,
    });
  };
}

const adminService = new AdminService(httpService);

export default adminService;
