import React, { useEffect, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { SnackbarProvider } from 'notistack';
import Notifier from 'containers/Notifier';
import Routes from './Routes';
import { fetchAuthenticatedUser, logout } from 'store/auth/actions';
import {
  makeSelectToken,
  makeSelectUser,
  userJwtDataSelector,
} from 'store/auth/selectors';
import AppBar from 'components/AppBar';
import styles from '../App/App.module.scss';
import SideBar from '../../components/SideBar';
import useWindowDimensions from '../../utils/dimensionHook';
import { useTranslation } from 'react-i18next';
import SnackbarComponent from '../../components/Snackbar/SnackbarComponent';
import { useHistory } from 'react-router-dom';
import { LOGIN } from '../../routes';
import Loader from '../Loader';

function App() {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const token = useSelector(makeSelectToken());
  const user = useSelector(makeSelectUser());
  const userJwtData = useSelector(userJwtDataSelector());
  const handleLogout = useCallback(() => dispatch(logout()), [dispatch]);

  const history = useHistory();

  const handleLanguageChange = useCallback(
    (val) => {
      i18n.changeLanguage(val);
    },
    [i18n]
  );

  const [isHidden, setIsHidden] = useState(false);
  const { width } = useWindowDimensions();

  useEffect(() => {
    if (width < 1200) {
      setIsHidden(true);
    } else {
      setIsHidden(false);
    }
  }, [width]);
  useEffect(() => {
    if (token) {
      dispatch(fetchAuthenticatedUser());
    } else {
      history.push(LOGIN);
    }
  }, [token, dispatch]);

  const renderLoadingIndicator = <div>Loading...</div>;

  const onPressHidden = (value) => {
    setIsHidden(value);
  };

  return (
    <HelmetProvider>
      <SnackbarProvider>
        <Helmet>
          <title>Lumanity Admin</title>
        </Helmet>
        {token && !user ? (
          renderLoadingIndicator
        ) : (
          <>
            <div style={{ display: 'flex' }}>
              <Loader />
              {user && (
                <div style={{ width: isHidden ? '80px' : '250px' }}>
                  <SideBar
                    onSideBarOpen={(value) => onPressHidden(value)}
                    user={user}
                  />
                </div>
              )}
              <div className={styles.container}>
                <div
                  className={styles.appBarDiv}
                  style={{
                    left: isHidden ? 80 : 250,
                    width: isHidden ? width - 80 : width - 250,
                  }}
                >
                  {user && (
                    <AppBar
                      onLanguageChange={handleLanguageChange}
                      user={user}
                      onLogout={handleLogout}
                      isHidden={isHidden}
                    />
                  )}
                </div>
                <div
                  className={styles.contentDiv}
                  style={{
                    left: user ? (isHidden ? 80 : 250) : 0,
                    top: user ? 80 : 0,
                    width: user ? (isHidden ? width - 80 : width - 250) : width,
                  }}
                >
                  <Routes user={userJwtData} />
                </div>
              </div>
            </div>
          </>
        )}
        <SnackbarComponent />
        <Notifier />
      </SnackbarProvider>
    </HelmetProvider>
  );
}

export default App;
