import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setSnackbar } from '../store/snackbar/actions';
import httpService from './HttpService';
import { logout } from '../store/auth/actions';

function ErrorHandler(props) {
  const dispatch = useDispatch();

  const handleError = async () => {
    httpService.addResponseInterceptors(
      (response) => response.data,
      (err) => {
        err = err instanceof Error ? { err }?.err?.response : err;
        if (err) {
          if (err?.data instanceof ArrayBuffer) {
            err.message = JSON.parse(
              new TextDecoder().decode(err?.data)
            ).message;
            setSnackbar({
              open: true,
              severity: 'error',
              message: err.message,
            });
          }
        } else {
          setSnackbar({
            open: true,
            severity: 'error',
            message: 'Something went wrong!',
          });
        }

        if (err.status === 401) {
          dispatch(logout());
        }
        if (err.status === 400 || err.status === 500 || err.status === 404) {
          dispatch(
            setSnackbar({
              open: true,
              severity: 'error',
              message: err?.data?.message
                ? err?.data?.message
                : err?.message
                ? err?.message
                : 'Something went wrong!',
            })
          );
          throw err;
        }
      }
    );
  };
  useEffect(() => {
    handleError();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return props.children;
}

export default ErrorHandler;
