import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import styles from './AppBar.module.scss';
import arrowDown from '../../assets/images/arrow-down.svg';
import { ReactComponent as Avatar } from '../../assets/icons/Avatar.svg';
import { ReactComponent as HearLogo } from '../../assets/images/logo/LogoBlack.svg';
import { InputBase, Paper } from '@material-ui/core';
import { BiSearch } from 'react-icons/bi';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import {
  mainSearchSaga,
  setIsSearchActive,
  setSearchTerm,
} from '../../store/admin/actions';
import { useHistory } from 'react-router-dom';

function AppBar({ user, onLogout, onLanguageChange, isHidden }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [langEl, setLangEl] = React.useState(null);
  const [termValue, setTermValue] = React.useState('');
  const [chosenLng, setChosenLng] = React.useState(
    localStorage.getItem('language') || 'en'
  );
  const open = Boolean(anchorEl);
  const openLang = Boolean(langEl);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickLang = (event) => {
    setLangEl(event.currentTarget);
  };

  const handleCloseLang = () => {
    setLangEl(null);
  };

  const handleChooseLng = (val) => {
    localStorage.setItem('language', val);
    setChosenLng(val);
    onLanguageChange(val);
    setLangEl(null);
  };

  const clearSearch = () => {
    dispatch(setSearchTerm(null));
    setTermValue('');
    dispatch(setIsSearchActive(false));
  };

  const onChangeTerm = (value) => {
    setTermValue(value?.trimStart());
    if (value.length === 0) {
      dispatch(setIsSearchActive(false));
    }
  };

  return (
    <div className={styles.innerWrapper}>
      <div style={{ flexDirection: 'row', display: 'flex' }}>
        <Paper component="form" variant="outlined" className={styles.root}>
          <BiSearch color={'#67657f'} size={25} className={styles.searchIcon} />
          <InputBase
            className={styles.inputField}
            placeholder={t('app_bar.search')}
            onChange={(e) => onChangeTerm(e.target.value)}
            value={termValue}
          />
        </Paper>
        <button
          disabled={!(termValue?.length > 1)}
          className={styles.searchButton}
          style={{
            backgroundColor:
              termValue?.length > 1
                ? 'rgb(222, 223, 228)'
                : 'rgba(222, 223, 228, 0.3)',
            color:
              termValue?.length > 1
                ? 'rgb(38, 33, 74)'
                : 'rgba(38, 33, 74, 0.3)',
          }}
          onClick={() => dispatch(mainSearchSaga({ term: termValue, history }))}
        >
          {t('app_bar.search')}
        </button>
        <button
          className={styles.clearSearchButton}
          onClick={() => clearSearch()}
        >
          {t('app_bar.clear_search')}
        </button>
      </div>

      <div className={styles.profileWrapper}>
        <Button
          id="basic-button"
          aria-controls={openLang ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={openLang ? 'true' : undefined}
          onClick={handleClickLang}
        >
          <p className={styles.languageText}>{chosenLng}</p>
        </Button>
        <Avatar className={styles.image} />
        <span className={styles.name}>
          {user?.data ? user?.data?.email : user?.email}
        </span>
        <Button
          id="basic-button"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          style={{
            minHeight: 24,
            maxHeight: 24,
            maxWidth: 24,
            minWidth: 24,
          }}
        >
          <img src={arrowDown} alt="arrow-down" />
        </Button>
        <HearLogo className={styles.hearLogo} />
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
          disableScrollLock={true}
        >
          <MenuItem onClick={onLogout}>{t('app_bar.link.logout')}</MenuItem>
        </Menu>
        <Menu
          id="basic-menu"
          anchorEl={langEl}
          open={openLang}
          onClose={handleCloseLang}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
          disableScrollLock={true}
        >
          <MenuItem onClick={() => handleChooseLng('en')}>
            {t('app_bar.languages.english')}
          </MenuItem>
          <MenuItem onClick={() => handleChooseLng('de')}>
            {t('app_bar.languages.deutsch')}
          </MenuItem>
        </Menu>
      </div>
    </div>
  );
}

AppBar.propTypes = {
  onLogout: PropTypes.func.isRequired,
};

export default AppBar;
