export const DASHBOARD = '/home';
export const LOGIN = '/login';
export const USER_PROFILE = '/profile';

export const PROJECT_LIST = '/projects';
export const SINGLE_PROJECT = '/projects/:projectId';
export const RESPONDENT_LIST = '/respondents';
export const SINGLE_RESPONDENT = '/respondents/:respondentId';
export const CLIENT_LIST = '/clients';
export const DATA_PAGE = '/data';
export const RECRUITER_LIST = '/recruiters';
export const SINGLE_RECRUITER = '/recruiters/:recruiterId';
export const SINGLE_ADMIN = '/admins/:adminId';
export const SINGLE_CLIENT = '/clients/:clientId';
export const JOURNEY_LIST = '/journeys';
export const DATA_ICONS = '/data/icons';
export const THERAPEUTIC_CATEGORIES = '/data/therapeutic-categories';
export const JOURNEY_TYPES = '/data/journey-types';
export const HEALTHCARE_PROFESSIONS = '/data/healthcare-professions';
export const PROJECT_TYPES = '/data/project-types';
export const RESPONDENT_TYPES = '/data/respondent-types';
export const COUNTRIES = '/data/countries';
export const CONDITIONS = '/data/conditions';
export const CARES = '/data/cares';
export const ADMINS = '/admins';
