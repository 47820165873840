import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import PrivateRoute from 'containers/PrivateRoute';
import PublicRoute from 'containers/PublicRoute';
import Dashboard from 'containers/Dashboard/Loadable';
import UserProfilePage from 'containers/UserProfilePage/Loadable';
import LoginPage from 'containers/LoginPage/Loadable';
import NotFoundPage from 'containers/NotFoundPage/Loadable';
import {
  CLIENT_LIST,
  DASHBOARD,
  JOURNEY_LIST,
  LOGIN,
  PROJECT_LIST,
  SINGLE_RESPONDENT,
  RECRUITER_LIST,
  RESPONDENT_LIST,
  SINGLE_PROJECT,
  SINGLE_RECRUITER,
  SINGLE_CLIENT,
  USER_PROFILE,
  DATA_ICONS,
  THERAPEUTIC_CATEGORIES,
  JOURNEY_TYPES,
  PROJECT_TYPES,
  RESPONDENT_TYPES,
  CONDITIONS,
  ADMINS,
  SINGLE_ADMIN,
  CARES,
  HEALTHCARE_PROFESSIONS,
} from 'routes';
import ProjectListPage from 'containers/ProjectListPage/Loadable';
import JourneyListPage from 'containers/JourneyListPage/Loadable';
import SingleRespondentPage from 'containers/SingleRespondentPage/Loadable';
import RespondentListPage from 'containers/RespondentListPage/Loadable';
import SingleRecruiterPage from 'containers/SingleRecruiterPage/Loadable';
import ClientListPage from 'containers/ClientListPage/Loadable';
import SingleClientPage from 'containers/SingleClientPage/Loadable';
import RecruiterListPage from 'containers/RecruiterListPage/Loadable';
import SingleProjectPage from 'containers/SingleProjectPage/Loadable';
import IconsPage from 'containers/IconsPage/Loadable';
import TherapeuticCategoryPage from 'containers/TherapeuticCategoryPage/Loadable';
import ProjectTypesPage from 'containers/ProjectTypesPage/Loadable';
import JourneyTypePage from 'containers/JourneyTypePage/Loadable';
import RespondentTypePage from 'containers/RespondentTypePage/Loadable';
import ConditionsPage from 'containers/ConditionsPage/Loadable';
import CarePage from 'containers/CaresPage/Loadable';
import AdminListPage from 'containers/AdminListPage/Loadable';
import SingleAdminPage from 'containers/SingleAdminPage/Loadable';
import HealthcareProfessionsPage from 'containers/HealthcareProfessionsPage/Loadable';

export default function Routes({ user }) {
  const checkIsAuthenticated = (array) => {
    return array.some((item) => item === user?.role);
  };
  return (
    <Switch>
      <PublicRoute exact path={LOGIN} component={LoginPage} />
      {user && (
        <PrivateRoute
          exact
          path={DASHBOARD}
          component={Dashboard}
          isAuthenticated={
            user && checkIsAuthenticated(['superadmin', 'admin'])
          }
        />
      )}
      {user && (
        <PrivateRoute exact path={USER_PROFILE} component={UserProfilePage} />
      )}
      {user && (
        <PrivateRoute
          exact
          path={CLIENT_LIST}
          component={ClientListPage}
          isAuthenticated={
            user && checkIsAuthenticated(['superadmin', 'admin'])
          }
        />
      )}
      {user && (
        <PrivateRoute
          exact
          path={ADMINS}
          component={AdminListPage}
          isAuthenticated={user && checkIsAuthenticated(['superadmin'])}
        />
      )}
      {user && (
        <PrivateRoute
          exact
          path={PROJECT_LIST}
          component={ProjectListPage}
          isAuthenticated={
            user && checkIsAuthenticated(['superadmin', 'admin'])
          }
        />
      )}
      {user && (
        <PrivateRoute
          exact
          path={SINGLE_PROJECT}
          component={SingleProjectPage}
          isAuthenticated={
            user && checkIsAuthenticated(['superadmin', 'admin'])
          }
        />
      )}
      {user && (
        <PrivateRoute
          exact
          path={JOURNEY_LIST}
          component={JourneyListPage}
          isAuthenticated={
            user && checkIsAuthenticated(['superadmin', 'admin'])
          }
        />
      )}
      {user && (
        <PrivateRoute
          exact
          path={RESPONDENT_LIST}
          component={RespondentListPage}
          isAuthenticated={
            user && checkIsAuthenticated(['superadmin', 'admin'])
          }
        />
      )}
      {user && (
        <PrivateRoute
          exact
          path={RECRUITER_LIST}
          component={RecruiterListPage}
          isAuthenticated={
            user && checkIsAuthenticated(['superadmin', 'admin'])
          }
        />
      )}
      {user && (
        <PrivateRoute
          exact
          path={CLIENT_LIST}
          component={ClientListPage}
          isAuthenticated={
            user && checkIsAuthenticated(['superadmin', 'admin'])
          }
        />
      )}
      {user && (
        <PrivateRoute
          exact
          path={SINGLE_RESPONDENT}
          component={SingleRespondentPage}
          isAuthenticated={
            user && checkIsAuthenticated(['superadmin', 'admin'])
          }
        />
      )}
      {user && (
        <PrivateRoute
          exact
          path={SINGLE_RECRUITER}
          component={SingleRecruiterPage}
          isAuthenticated={
            user && checkIsAuthenticated(['superadmin', 'admin'])
          }
        />
      )}
      {user && (
        <PrivateRoute
          exact
          path={SINGLE_ADMIN}
          component={SingleAdminPage}
          isAuthenticated={user && checkIsAuthenticated(['superadmin'])}
        />
      )}
      {user && (
        <PrivateRoute
          exact
          path={SINGLE_CLIENT}
          component={SingleClientPage}
          isAuthenticated={
            user && checkIsAuthenticated(['superadmin', 'admin'])
          }
        />
      )}
      {user && (
        <PrivateRoute
          exact
          path={DATA_ICONS}
          component={IconsPage}
          isAuthenticated={user && checkIsAuthenticated(['superadmin'])}
        />
      )}
      {user && (
        <PrivateRoute
          isAuthenticated={user && checkIsAuthenticated(['superadmin'])}
          exact
          path={THERAPEUTIC_CATEGORIES}
          component={TherapeuticCategoryPage}
        />
      )}
      {user && (
        <PrivateRoute
          isAuthenticated={user && checkIsAuthenticated(['superadmin'])}
          exact
          path={PROJECT_TYPES}
          component={ProjectTypesPage}
        />
      )}
      {user && (
        <PrivateRoute
          isAuthenticated={user && checkIsAuthenticated(['superadmin'])}
          exact
          path={JOURNEY_TYPES}
          component={JourneyTypePage}
        />
      )}
      {user && (
        <PrivateRoute
          isAuthenticated={user && checkIsAuthenticated(['superadmin'])}
          exact
          path={RESPONDENT_TYPES}
          component={RespondentTypePage}
        />
      )}
      {user && (
        <PrivateRoute
          isAuthenticated={user && checkIsAuthenticated(['superadmin'])}
          exact
          path={CONDITIONS}
          component={ConditionsPage}
        />
      )}
      {user && (
        <PrivateRoute
          isAuthenticated={user && checkIsAuthenticated(['superadmin'])}
          exact
          path={CARES}
          component={CarePage}
        />
      )}
      {/*{user && (*/}
      {/*  <PrivateRoute*/}
      {/*    isAuthenticated={user && checkIsAuthenticated(['superadmin'])}*/}
      {/*    exact*/}
      {/*    path={HEALTHCARE_PROFESSIONS}*/}
      {/*    component={HealthcareProfessionsPage}*/}
      {/*  />*/}
      {/*)}*/}
      {/*<PrivateRoute*/}
      {/*  isAuthenticated={user?.hasOwnProperty('super')}*/}
      {/*  exact*/}
      {/*  path={COUNTRIES}*/}
      {/*  component={CountryPage}*/}
      {/*/>*/}
      <Route exact path="/">
        <Redirect to={DASHBOARD} />
      </Route>
      <Route path="*" component={NotFoundPage} />
    </Switch>
  );
}
